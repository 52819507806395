import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheElements extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The Elements - Martial Arts Illustrated, UK Oct 2011</h1>
                <StaticImage src="../../../images/elements/ResizedImage600826-2780271.jpg" alt="The Elements Page 1"/>
                <StaticImage src="../../../images/elements/ResizedImage600853-577634.jpg" alt="The Elements Page 2"/>
                <StaticImage src="../../../images/elements/ResizedImage600826-8419971.jpg" alt="The Elements Page 3"/>
                <StaticImage src="../../../images/elements/ResizedImage600825-4394032.jpg" alt="The Elements Page 4"/>
            </Layout>);
    }
}

export default TheElements;
